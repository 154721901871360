import {createApp, h} from 'vue'
import App from './App.vue'
import router from './router'
import './less/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import $ from 'jquery'
import 'slick-carousel'
import '@/assets/slick/slick.css'
import '@/assets/slick/slick-theme.css'
import '@/js/app'
import i18n from "@/i18n";
import AOS from 'aos'
import 'aos/dist/aos.css'

window.axios = require('axios');
window.jQuery = window.$ = $

const app = createApp({
    render: () => h(App),
    mounted() {
        AOS.init()
    },
})

localStorage.setItem('selectedLanguage', 'en')
app.config.globalProperties.$locale = localStorage.selectedLanguage
app.config.globalProperties.trans = i18n.global.t;

app.mixin({
    data() {
        return {
            title: 'Casa Italia Global'
        }
    },
    methods: {
        setTitle: function (customTitle) {
            if (customTitle) {
                document.title = customTitle + " | " + this.title
            } else {
                document.title = this.title
            }
        }
    },
})
app.use(router, i18n)
app.mount('#app')

