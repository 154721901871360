<template>
    <footer class="footer" id="footer">
        <div class="container">
            <div class="top-part">
                <div class="left-part">
                    <img src="../assets/icons/logo.png" class="footer-logo" alt="">
                </div>
                <div class="center-part">
                    <router-link class="nav-link" :to="`/${this.$locale}`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.HOME") }}</p>
                    </router-link>
                    <router-link class="nav-link" :to="`/${this.$locale}/services`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.SERVICES") }}</p>
                    </router-link>
                    <router-link class="nav-link" :to="`/${this.$locale}/about`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.ABOUT") }}</p>
                    </router-link>
                    <router-link class="nav-link" :to="`/${this.$locale}/contact`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.CONTACT") }}</p>
                    </router-link>
                </div>
                <div class="right-part">
                    <a href="https://www.instagram.com/cig.sg/" target="_blank">
                        <img src="../assets/icons/facebook-icon.svg" class="link-icon" alt="">
                    </a>
                    <a href="https://www.instagram.com/cig.sg/" target="_blank">
                        <img src="../assets/icons/instagram-icon.svg" class="link-icon" alt="">
                    </a>
                </div>
            </div>
            <div class="bottom-part">
                <p class="footer-bottom-text">
                    Copyright &copy; {{ new Date().getFullYear() }} - Casa Italia Global. {{ this.trans("FOOTER.ALL_RIGHTS_RESERVED") }}
                </p>
                <p class="footer-bottom-text mt-2">
                    {{ this.trans("FOOTER.DEVELOPED_BY") }} <b><a href="http://afillix.com/#/" target="_blank">Afillix</a></b>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
}
</script>

<style scoped>

</style>